import React, { useState, useEffect } from "react";
import "./App.css";
import PromptInput from "./components/PromptInput.js";
import Login from "./components/Login.js"; // Assuming Login is in the same src directory
import logo from "./medialab-light.png"; // Import the logo image

const initialZ = Array(512).fill(0);
const tickScale = 80; // Increase the scale to magnify changes
const isPasswordProtectionEnabled = false; // Set this to false to disable password protection

const Carousel = ({ images, onSelect, onRemove }) => (
  <div
    className="carousel-container"
    style={{ height: window.innerHeight * 0.2 }}
  >
    {images.map((image) => (
      <div key={image.id} style={{ position: "relative", marginRight: "10px" }}>
        <img
          src={image.imgSrc}
          alt="Captured"
          style={{
            width: window.innerHeight * 0.2 - 10,
            height: window.innerHeight * 0.2 - 10,
            cursor: "pointer",
            borderRadius: "10px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(image.zVec);
          }}
        />
        <button
          className="carousel-close-button"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(image.id);
          }}
        >
          ✕
        </button>
      </div>
    ))}
  </div>
);

const Modal = ({ onClose }) => (
  <div className="modal-backdrop">
    <div className="modal-content">
      <button className="close-button" onClick={(e) => {
        e.stopPropagation();
        onClose()}}>
        ✕
      </button>
      <h2>Welcome to Form Forge</h2>
      <p style={{ textAlign: "left" }}>
        Form Forge is a creative system for exploring the latent space of
        architectural sketches by François Blanciak's in his book{" "}
        <a href="https://mitpress.mit.edu/9780262026307/siteless/" target="_blank">
          <i>SITELESS: 1001 Building Forms</i>
        </a>
        . Using a fine-tuned StyleGAN2-ADA model, it allows users to directly
        manipulate latent variables to navigate a diverse array of building
        forms. This tool aims to simplify interaction with complex,
        high-dimensional spaces, supporting creative processes in architectural
        design.
      </p>
      <hr />
      <h2>How to Use</h2>
      <ul style={{ textAlign: "left" }}>
        <li>
          <b>Explore and Adjust Latent Variables:</b>
          <p>
            Use the 512 tick marks surrounding the central image to adjust the
            latent variables. Move your cursor outside the ring to increase the
            length of a tick (intensifying the effect of that variable), or
            inside to decrease it.
          </p>
        </li>
        <li>
          <b>View and Save Forms:</b>
          <p>
            As you adjust variables, the central image updates in real-time.
            Click anywhere on the screen to save the current form and its vector
            state to the Saved Samples Carousel at the bottom of the interface.
          </p>
        </li>
        <li>
          <b>Manipulate Saved Samples:</b>
          <p>
            Click on any saved image in the carousel to restore it to the
            central display area and apply its corresponding tick settings.
            Remove images from the carousel by clicking the 'X' on the top right
            corner of each thumbnail.
          </p>
        </li>
        <li>
          <b>Use Decay Functions for Reset:</b>
          <p>
            Activate the decay function to gradually reset all ticks and
            variables to zero, or use the immediate reset button for an instant
            return to the starting state. Adjust the decay rate and tick
            sensitivity using the sliders in the bottom-left corner.
          </p>
        </li>
      </ul>
    </div>
  </div>
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight * 0.6);
  const [zArr, setZArr] = useState(initialZ);
  const [generatedImageBase64, setGeneratedImageBase64] = useState(
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
  );
  const radius = Math.min(width, height) / 3;
  const innerThreshold = radius - height / 10; // Distance threshold inside the circle
  const outerThreshold = radius + height / 10; // Distance threshold outside the circle
  const [distance, setDistance] = useState(0);

  const [index, setIndex] = useState(0);
  const [decayActive, setDecayActive] = useState(false);
  const [images, setImages] = useState([]); // Array of {id, imgSrc, zVec} objects
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [sensitivity, setSensitivity] = useState(0.02); // Default sensitivity
  const [decayRate, setDecayRate] = useState(0.9); // Default decay rate in %
  // const baseUrl = "http://${window.location.hostname}:5001"; // Assuming the backend is running on localhost
  const baseUrl = "http://18.27.79.169:5001"; // Assuming the backend is running on localhost

  // Event handlers for sliders
  const handleSensitivityChange = (event) => {
    const newSensitivity = parseFloat(event.target.value);
    console.log("New Sensitivity:", newSensitivity);
    setSensitivity(newSensitivity);
  };

  const handleDecayRateChange = (event) => {
    const newDecayRate = parseFloat(event.target.value);
    console.log("New Decay Rate:", newDecayRate);
    setDecayRate(newDecayRate);
  };

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight * 0.6);
    };

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateZArr = (mouseX, mouseY) => {
    const centerX = width / 2;
    const centerY = height / 2 + 90; // Adjust for the height of the header
    setDistance(Math.sqrt((mouseX - centerX) ** 2 + (mouseY - centerY) ** 2));
    const angle = Math.atan2(mouseY - centerY, mouseX - centerX);
    let newIndex = Math.floor(((angle + Math.PI) / (2 * Math.PI)) * 512) % 512;
    if (newIndex < 0) newIndex += 512; // Ensure the index wraps around correctly

    // console.log(`Mouse Position: (${mouseX}, ${mouseY})`);
    // console.log(`Center Position: (${centerX}, ${centerY})`);
    // console.log("innerThreshold:", innerThreshold);
    // console.log("outerThreshold:", outerThreshold);
    // console.log(`Distance: ${distance}`);
    // console.log(`Angle (degrees): ${(angle * 180) / Math.PI}`);
    // console.log(`Calculated Index: ${newIndex}`);

    setIndex(newIndex);

    if (distance > innerThreshold && distance < outerThreshold) {
      const newZ = zArr.map((c, i) => {
        if (i === newIndex) {
          if (distance > radius) {
            return c < 3 ? c + sensitivity : c;
          } else {
            return c > -3 ? c - sensitivity : c;
          }
        }
        return c;
      });
      setZArr(newZ);
    }
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
      updateZArr(event.clientX, event.clientY);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [width, height, zArr, radius, index, innerThreshold, outerThreshold]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateZArr(mousePos.x, mousePos.y);
    }, 100);

    return () => clearInterval(interval);
  }, [mousePos, zArr, radius, index, innerThreshold, outerThreshold]);

  useEffect(() => {
    let interval;
    if (decayActive) {
      interval = setInterval(() => {
        setZArr((zArr) =>
          zArr.map((z) => (Math.abs(z * decayRate) <= 0.01 ? 0 : z * decayRate))
        ); // Set to 0 if within +/- 0.01 of 0, otherwise use the decayed value
      }, 100); // Decay rate: every millisecond
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [decayActive]);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  if (isPasswordProtectionEnabled && !isAuthenticated) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  const resetZArr = () => {
    setZArr(initialZ); // Resets all values to the initial state, assumed to be zero
  };

  const tickMarks = zArr.map((z, i) => {
    const angle = (i / 512) * 2 * Math.PI;

    const baseRadius = radius - 1;

    // Calculate the maximum extension based on the tickScale and bounds
    const maxExtension = tickScale * 3;
    let extendedRadius = radius + (z / 3) * maxExtension; // Normalize z to be within -3 to 3 range

    // Ensure the extended radius does not exceed the inner and outer thresholds
    if (extendedRadius > outerThreshold) {
      extendedRadius = outerThreshold;
    } else if (extendedRadius < innerThreshold) {
      extendedRadius = innerThreshold;
    }

    const x1 = -Math.cos(angle) * baseRadius + width / 2;
    const y1 = -Math.sin(angle) * baseRadius + height / 2;
    const x2 = -Math.cos(angle) * extendedRadius + width / 2;
    const y2 = -Math.sin(angle) * extendedRadius + height / 2;

    let color = "white";
    if (i === index && distance > innerThreshold && distance < outerThreshold) {
      color = zArr[i] > 0 ? "green" : "red";
    }

    return (
      <line
        key={i}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth="2"
      />
    );
  });

  const captureImage = () => {
    if (!isModalOpen) {
      const newImage = {
        id: images.length,
        imgSrc: generatedImageBase64, // Use the existing base64 image
        zVec: [...zArr], // Current state of the Z array
      };
      setImages([...images, newImage]);
    }
  };

  const selectImage = (zVec) => {
    setZArr(zVec); // Update zArr directly
  };

  const removeImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const handleGlobalClick = (event) => {
    captureImage();
  };

  // Rendering the additional boundary rings:
  const boundaryRings = (
    <g>
      <circle
        cx={width / 2}
        cy={height / 2}
        r={innerThreshold}
        stroke={distance < innerThreshold ? "yellow" : "grey"}
        strokeWidth="1"
        fill="none"
      />
      <circle
        cx={width / 2}
        cy={height / 2}
        r={outerThreshold}
        stroke={distance > outerThreshold ? "yellow" : "grey"}
        strokeWidth="1"
        fill="none"
      />
    </g>
  );

  return (
    <div className="App" onClick={handleGlobalClick}>
      <div
        className="header"
        style={{
          backgroundColor: "#000",
          color: "white",
          paddingLeft: "20px",
          borderBottom: "1px solid white",
          height: "90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Form Forge</h1>
        <a href="https://www.media.mit.edu/" target="_blank">
          <img
            src={logo}
            alt="Logo"
            style={{ height: "80px", marginTop: "5px", marginRight: "-8px" }}
          />
        </a>
      </div>
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
      <svg width={width} height={height}>
        <foreignObject
          x={width / 2 - radius / 2}
          y={height / 2 - radius / 2}
          width={radius}
          height={radius}
        >
          <div
            style={{
              width: "512px",
              height: "512px",
              transform: `scale(${radius / 512})`,
              transformOrigin: "top left",
            }}
          >
            <PromptInput
              zArr={zArr}
              baseUrl={baseUrl}
              generatedImageBase64={generatedImageBase64}
              setGeneratedImageBase64={setGeneratedImageBase64}
            />
          </div>
        </foreignObject>
        {/* <circle cx={width / 2} cy={height / 2} r={5} fill="red" />{" "} */}
        {/* Center dot */}
        {boundaryRings}
        {tickMarks}
      </svg>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "flex-end", // Align items to the end along the cross axis
          padding: "5px",
          color: "white",
          height: "100px",
          position: "absolute",
          bottom: window.innerHeight * 0.2 + 30 + "px",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "none",
          }}
        >
          <div className="slider-container">
            <div
              style={{
                width: "135px",
                textAlign: "right",
                paddingRight: "10px",
              }}
            >
              <label htmlFor="sensitivity" className="slider-label">
                Sensitivity:
              </label>
            </div>
            <div style={{ flex: 1 }}>
              <input
                id="sensitivity"
                type="range"
                min="0.01"
                max="0.1"
                step="0.01"
                value={sensitivity.toString()}
                onChange={handleSensitivityChange}
                onClick={(e) => e.stopPropagation()}
                className="slider"
                style={{ width: "90%" }} // Ensure the slider takes the full width
              />
            </div>
          </div>
          <div className="slider-container">
            <div
              style={{
                width: "141px",
                textAlign: "right",
                paddingRight: "4px",
              }}
            >
              <label htmlFor="decayRate" className="slider-label">
                Decay Rate:
              </label>
            </div>
            <div style={{ flex: 1 }}>
              <input
                id="decayRate"
                type="range"
                min="0.1"
                max="0.9"
                step="0.1"
                value={decayRate.toString()}
                onChange={handleDecayRateChange}
                onClick={(e) => e.stopPropagation()}
                className="slider"
                style={{ width: "90%" }}
              />
            </div>
          </div>
          <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <button
              className="system-button"
              onClick={(e) => {
                e.stopPropagation();
                setDecayActive(!decayActive);
              }}
            >
              {decayActive ? "Stop Decay" : "Start Decay"}
            </button>
            <button
              className="system-button"
              onClick={(e) => {
                e.stopPropagation();
                setZArr(Array(512).fill(0));
              }}
            >
              Reset Values
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          <button
            className="system-button"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            About ⓘ
          </button>
        </div>
      </div>

      <Carousel images={images} onSelect={selectImage} onRemove={removeImage} />
    </div>
  );
}

export default App;
