import styles from './PromptInput.css';
import React, { useState, useRef, useEffect, useMemo } from "react";

export default function PromptInput({zArr, baseUrl, generatedImageBase64, setGeneratedImageBase64}){


    // console.log(baseUrl)

    const [prompt, setPrompt] = useState("");

    // const submitButton = () =>{
    //     // console.log(prompt)

        
    //     }

    useEffect(() => {
      fetch(baseUrl + "/generate_image", {
        method: "POST",
        mode: "cors",
        cache: "reload",
        credentials: "same-origin", 
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"z": 
        [zArr]}), 
      }).then((response) => response.json())
        .then((data) => {
        // console.log(data)
        var image_str = data["image"].slice(2, -1);
        // console.log(image_str)
        setGeneratedImageBase64("data:image/gif;base64,"+image_str)
      });
      }, [generatedImageBase64]);

    return(
    <div className='center'>
        {/* <label>
            Prompt:
            <input type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} name="prompt" />
        </label>
        <input type="submit" value="Submit" onClick={submitButton}/> */}
        <img src={generatedImageBase64} />
    </div>
    )
}
