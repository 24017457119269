import React, { useState } from "react";

const Login = ({ onLoginSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('http://18.27.79.169:5001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: 'admin', password: password })
      });
      
      if (response.ok) {
        onLoginSuccess();
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      setError('Network error: ' + error.message);
    }
  };

  return (
    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div style={{ padding: "20px", background: "white", borderRadius: "10px" }}>
        <h1>Form Forge</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter your password"
          style={{ margin: "10px 0", padding: "8px", width: "200px" }}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default Login;
